import axios from "axios";

class APIstatics {
  static isRefreshing = false;
  static failedApi = [];
}

export default function Api(baseURL) {
  let TOKEN_KEY = "inst-token";
  let interceptorsChanged = false;

  function getBaseURL() {
    return "https://" + window.location.host + "/api";
  }

  function post(api, hdata, scallback, ecallback) {
    let req;
    if (typeof api !== "string" && "api" in api) {
      req = api;
    } else {
      req = { api, hdata, scallback, ecallback };
    }

    if (!APIstatics.isRefreshing) {
      axios({
        method: "post",
        url: getBaseURL() + req.api,
        data: req.hdata,
      })
        .then((res) => preRes(req, res))
        .catch((err) =>
          req.ecallback ? req.ecallback(err) : console.error(err)
        );
    } else {
      APIstatics.failedApi.push(req);
    }
  }

  function preRes(payload, response) {
    const res = response["data"];
    if (res && !res["s"] && res["f"] && res["f"] === 1003) {
      console.log("APIstatics.failedApi.push");
      APIstatics.failedApi.push(payload);
      if (!APIstatics.isRefreshing) {
        APIstatics.isRefreshing = true;
        axios({
          method: "post",
          url: getBaseURL() + "/auth/refresh",
          data: {},
        })
          .then((rresponse) => {
            APIstatics.isRefreshing = false;
            const rres = rresponse["data"];
            if (rres && rres["s"]) {
              saveToken(rres["d"]["access_token"]);
              while (APIstatics.failedApi.length > 0) {
                const rereq = APIstatics.failedApi.pop();
                post(rereq);
              }
            } else {
              clearStorage();
              window.location.href = "/sign-in";
              // redirect to login
            }
          })
          .catch((err) => {
            APIstatics.isRefreshing = false;
            console.error(err);
          });
      }
    } else payload.scallback(res);

    return this;
  }

  function saveToken(token) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  function clearStorage() {
    window.localStorage.clear();
  }

  function getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  if (!interceptorsChanged) {
    interceptorsChanged = true;
    axios.interceptors.request.use((config) => {
      if (getToken() !== null && getToken() !== "")
        config.headers.Authorization = "Bearer " + getToken();
      return config;
    });
  }

  this.post = post;
}
