import { configureStore } from "@reduxjs/toolkit";
import instituteConfigReducer from "../features/instituteConfig";
import currentUserReducer from "../features/currentUser";

export const store = configureStore({
  reducer: {
    instituteConfig: instituteConfigReducer,
    currentUser: currentUserReducer,
  },
});
