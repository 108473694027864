import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Api from "./ajax";

import PageLoader from "./components/common/PageLoader";

import { setInstConfig } from "./features/instituteConfig";
import { setCurrentUser } from "./features/currentUser";
import { useDispatch } from "react-redux";

const Login = lazy(() => import("./components/Login"));
const AdminRoutes = lazy(() => import("./components/AdminRoutes"));

const api = new Api();

const MainApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    api.post("/has-auth", {}, (res) => {
      if (res.s) {
        dispatch(setInstConfig(res.d.inst_config));
        dispatch(setCurrentUser(res.d.current_user));
      }
      setIsLoggedIn(res.s);
    });
  }, [dispatch]);

  let routeComponents;

  if (isLoggedIn === null) {
    routeComponents = <PageLoader />;
  } else {
    if (!isLoggedIn) {
      routeComponents = (
        <BrowserRouter>
          <Routes>
            <Route
              path="sign-in"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Login authenticate={() => setIsLoggedIn(true)} />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/sign-in" />}></Route>
          </Routes>
        </BrowserRouter>
      );
    } else {
      routeComponents = (
        <Suspense fallback={<PageLoader />}>
          <AdminRoutes />
        </Suspense>
      );
    }
  }

  return <>{routeComponents}</>;
};

export default MainApp;
